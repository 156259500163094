<template>
  <div class="answer_wrap">
    <div class="view-empty-wrapper manage-wrapper">
      <div class="container">
        <div class="explain">
          <div class="explain_title">{{ workInfo.workname }}</div>
          <div class="explain_item">作业主题：{{ workTheme }}</div>
          <div class="explain_item">
            <div>作业说明：</div>
            <div v-html="explain"></div>
          </div>
          <div class="explain_item">
            提交时间：{{ workInfo.beginTime }} --- {{ workInfo.endTime }}
          </div>
          <div class="explain_item">积分：100积分</div>
          <div class="explain_item">能力标准：{{ powers }}</div>

        </div>
        <!-- 中部生成的表单 -->
        <div class="center-board">
          <div class="workContent">
            <span>作业内容</span>
          </div>
          <!-- 生成的表单 -->
          <el-scrollbar class="center-scrollbar">
            <el-row class="center-board-row" :gutter="formConf.gutter">
              <el-form :size="formConf.size" :label-position="formConf.labelPosition" :disabled="formConf.disabled"
                :label-width="formConf.labelWidth + 'px'">
                <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup"
                  :options="dragOptions">
                  <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey" :drawing-list="drawingList"
                    :current-item="item" :index="index" :active-id="activeId" :form-conf="formConf"
                    @activeItem="activeFormItem" @copyItem="drawingItemCopy" @deleteItem="drawingItemDelete" />
                </draggable>
                <div v-show="!drawingList.length" class="empty-info">
                  从左侧拖入或点选组件进行表单设计
                </div>
              </el-form>
            </el-row>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {
    debounce
  } from "throttle-debounce";
  import {
    saveAs
  } from "file-saver";
  import ClipboardJS from "clipboard";
  import render from "@/components/render/render";
  import JsonDrawer from "./JsonDrawer";
  import RightPanel from "./RightPanel";
  import {
    inputComponents,
    selectComponents,
    layoutComponents,
    formConf
  } from "@/components/generator/config_answer";
  import {
    exportDefault,
    beautifierConf,
    isNumberStr,
    titleCase,
    deepClone
  } from "@/utils/index";
  import {
    makeUpHtml,
    vueTemplate,
    vueScript,
    cssStyle
  } from "@/components/generator/html";
  import {
    makeUpJs
  } from "@/components/generator/js";
  import {
    makeUpCss
  } from "@/components/generator/css";
  import drawingDefalut from "@/components/generator/drawingDefalut";
  import logo from "@/assets/logo.png";
  import CodeTypeDialog from "./CodeTypeDialog";
  import DraggableItem from "./DraggableItem3";
  import {
    getDrawingList,
    saveDrawingList,
    getIdGlobal,
    saveIdGlobal,
    getFormConf
  } from "@/utils/db";
  import loadBeautifier from "@/utils/loadBeautifier";

  let beautifier;
  const emptyActiveData = {
    style: {},
    autosize: {}
  };
  let oldActiveId;
  let tempActiveData;
  const drawingListInDB = getDrawingList();
  const formConfInDB = getFormConf();
  const idGlobal = getIdGlobal();

  export default {
    components: {
      draggable,
      render,
      JsonDrawer,
      RightPanel,
      CodeTypeDialog,
      DraggableItem
    },
    data() {
      return {
        dragOptions: {
          sort: false //定义生成的表单是否可以拖拽
        },
        logo,
        idGlobal,
        formConf,
        inputComponents,
        selectComponents,
        layoutComponents,
        labelWidth: 100,
        drawingList: drawingDefalut,
        drawingData: {},
        activeId: drawingDefalut[0].formId,
        drawerVisible: false,
        formData: {},
        dialogVisible: false,
        jsonDrawerVisible: false,
        generateConf: null,
        showFileName: false,
        activeData: drawingDefalut[0],
        saveDrawingListDebounce: debounce(340, saveDrawingList),
        saveIdGlobalDebounce: debounce(340, saveIdGlobal),
        questionForm: [],
        explain: "", //自定义的作业说明
        answerAgain: false,
        answerId: "", //二次答题的答案ID 二次提交必要参数
        activeNames: ["1"],
        answerAgain: false,
        workInfoAll: "", //作业全部信息
        workInfo: "", //问卷信息
        workTheme: "", //作业主题
        beginTime: "", //开始答卷的时间戳
        powers: "", //二级能力标准
        prevInfo: {
          prevPath: '/',
          prevPage: '课程详情',
          ancestryPath: '/',
          ancestryPage: '我的课程',
        }, //上个页面名称
      };
    },
    computed: {
      prevPage() {
        if (this.$route.query.from == 'course') {
          return this.prevInfo = {
            prevPath: `/course/${this.$route.query.courseId}?index=0`,
            prevPage: '课程详情',
            ancestryPath: 'course',
            ancestryPage: '我的课程',
          }
        } else {
          return this.prevInfo = {
            prevPath: `/mytask/detail/${this.workInfoAll.themeId}`,
            prevPage: this.workInfoAll.themeName,
            ancestryPath: 'mytask',
            ancestryPage: '我的任务',
          }
        }
      }
    },
    watch: {
      // eslint-disable-next-line func-names
      "activeData.__config__.label": function (val, oldVal) {
        if (
          this.activeData.placeholder === undefined ||
          !this.activeData.__config__.tag ||
          oldActiveId !== this.activeId
        ) {
          return;
        }
        this.activeData.placeholder =
          this.activeData.placeholder.replace(oldVal, "") + val;
      },
      activeId: {
        handler(val) {
          oldActiveId = val;
        },
        immediate: true
      },
      drawingList: {
        handler(val) {
          this.saveDrawingListDebounce(val);
          if (val.length === 0) this.idGlobal = 100;
        },
        deep: true
      },
      idGlobal: {
        handler(val) {
          this.saveIdGlobalDebounce(val);
        },
        immediate: true
      },
    },

    mounted() {
      if (Array.isArray(drawingListInDB) && drawingListInDB.length > 0) {
        this.drawingList = drawingListInDB;
      } else {
        this.drawingList = drawingDefalut;
      }
      this.activeFormItem(this.drawingList[0]);
      if (formConfInDB) {
        this.formConf = formConfInDB;
      }
      loadBeautifier(btf => {
        beautifier = btf;
      });

    },
    deactivated() {
      this.prevInfo = {
        prevPath: '/',
        prevPage: '课程详情',
        ancestryPath: '/',
        ancestryPage: '我的课程',
      }
    },
    activated() {
      this.$Api.Form.getStuForm(this.$route.query.questionId)
        .then(value => {
          console.log(value);
          this.workInfoAll = value.data;
          this.workTheme = value.data.themeName;
          let content = JSON.parse(value.data.content);
          // console.log("问卷详情--", content);
          this.workInfo = JSON.parse(value.data.title);
          // console.log('作业信息--',this.workInfo)
          content.map(item => {
            //将作业说明单出拿出来
            if (item.__config__.tag == "tinymce") {
              this.explain = item.__config__.defaultValue;
            }
            //根据表单类型修改对应的默认值类型 不然会报错
            if (item.__config__.tag == "el-checkbox-group") {
              item.__config__.defaultValue = [];
            } else {
              item.__config__.defaultValue = "";
            }
            //将表单改为只读或者禁用状态
            if (item.__config__.tag == "el-input") {
              item.readonly = true;
            } else {
              item.disabled = true;
            }
          });
          content = content.filter(item => {
            // return item.__vModel__ != "explain";
            return item.__config__.tag != "tinymce";
          });
          // console.log("题干---", content);
          localStorage.setItem("drawingItems", JSON.stringify(content));
          this.drawingList = JSON.parse(localStorage.getItem("drawingItems"));
          //获取当前时间戳
          this.beginTime = new Date().getTime();
          // console.log("开始答题时间戳--", this.beginTime);
        })
        .catch(reason => {
          console.log(reason);
        });
      this.getpowers();
    },
    methods: {
      //获取问卷顶部能力标准
      getpowers() {
        this.$Api.Form.getSecondPower(this.$route.query.questionId)
          .then(value => {
            // console.log('二级能力标准',value)
            let str = "";
            value.data.map(item => {
              return (str += item.title + ",");
            });
            this.powers = str.slice(0, str.length - 1);
          })
          .catch(reason => {
            console.log(reason);
          });
      },
      //时分秒换算
      date_format(micro_second) {
        // 总秒数
        var second = Math.floor(micro_second / 1000);
        var min = Math.floor((second / 60) % 60);
        // 秒
        var sec = Math.floor(second % 60);
        return min + "分钟" + sec + "秒";
      },
      //获取学员二次答卷的问卷信息
      getForm() {
        this.$Api.Form.checkForm(
            // JSON.parse(sessionStorage.getItem("userinfo")).id,
            this.$store.state.user.userInfo.id,
            this.$route.query.questionId
          )
          .then(res => {
            this.workInfoAll = res.data;
            console.log(res);
            this.answerId = res.data.id;
            let content = JSON.parse(res.data.qContent);
            // console.log("问卷列表---", content);
            content.map(item => {
              //将作业说明取出
              if (item.__vModel__ == "explain") {
                this.explain = item.__config__.defaultValue;
              }
              //根据表单类型修改对应的默认值类型 不然会报错
              if (item.__config__.tag == "el-checkbox-group") {
                item.__config__.defaultValue = [];
              } else {
                item.__config__.defaultValue = "";
              }
            });
            //将作业说明过滤出去只显示问卷内容
            content = content.filter(item => {
              return item.__vModel__ != "explain";
            });
            //获取学员回答的答案
            let resultlist = JSON.parse(res.data.content);
            // console.log("学员答案", resultlist);
            //将获取的答案赋值给对应的空问卷
            for (var i = 0; i < content.length; i++) {
              if (resultlist[i].id == content[i].__vModel__) {
                content[i].__config__.defaultValue = resultlist[i].result;
              }
            }
            //将处理后的表单渲染到页面
            localStorage.setItem("drawingItems", JSON.stringify(content));
            this.drawingList = JSON.parse(localStorage.getItem("drawingItems"));
          })
          .catch(err => {
            console.log(err);
          });
      },
      activeFormItem(currentItem) {
        this.activeData = currentItem;
        this.activeId = currentItem.__config__.formId;
      },
      onEnd(obj) {
        if (obj.from !== obj.to) {
          this.activeData = tempActiveData;
          this.activeId = this.idGlobal;
        }
      },
      addComponent(item) {
        const clone = this.cloneComponent(item);
        this.drawingList.push(clone);
        this.activeFormItem(clone);
      },
      cloneComponent(origin) {
        const clone = deepClone(origin);
        const config = clone.__config__;
        config.span = this.formConf.span; // 生成代码时，会根据span做精简判断
        this.createIdAndKey(clone);
        clone.placeholder !== undefined && (clone.placeholder += config.label);
        tempActiveData = clone;
        return tempActiveData;
      },
      createIdAndKey(item) {
        const config = item.__config__;
        config.formId = ++this.idGlobal;
        config.renderKey = `${config.formId}${+new Date()}`; // 改变renderKey后可以实现强制更新组件
        if (config.layout === "colFormItem") {
          item.__vModel__ = `field${this.idGlobal}`;
        } else if (config.layout === "rowFormItem") {
          config.componentName = `row${this.idGlobal}`;
          !Array.isArray(config.children) && (config.children = []);
          delete config.label; // rowFormItem无需配置label属性
        }
        if (Array.isArray(config.children)) {
          config.children = config.children.map(childItem =>
            this.createIdAndKey(childItem)
          );
        }
        return item;
      },
      AssembleFormData() {
        this.formData = {
          fields: deepClone(this.drawingList),
          ...this.formConf
        };
      },
      generate(data) {
        const func = this[`exec${titleCase(this.operationType)}`];
        this.generateConf = data;
        func && func(data);
      },
      execRun(data) {
        this.AssembleFormData();
        this.drawerVisible = true;
      },
      execDownload(data) {
        const codeStr = this.generateCode();
        const blob = new Blob([codeStr], {
          type: "text/plain;charset=utf-8"
        });
        saveAs(blob, data.fileName);
      },
      execCopy(data) {
        document.getElementById("copyNode").click();
      },
      drawingItemCopy(item, list) {
        let clone = deepClone(item);
        clone = this.createIdAndKey(clone);
        list.push(clone);
        this.activeFormItem(clone);
      },
      drawingItemDelete(index, list) {
        list.splice(index, 1);
        this.$nextTick(() => {
          const len = this.drawingList.length;
          if (len) {
            this.activeFormItem(this.drawingList[len - 1]);
          }
        });
      },
      generateCode() {
        const {
          type
        } = this.generateConf;
        this.AssembleFormData();
        const script = vueScript(makeUpJs(this.formData, type));
        const html = vueTemplate(makeUpHtml(this.formData, type));
        const css = cssStyle(makeUpCss(this.formData));
        return beautifier.html(html + script + css, beautifierConf.html);
      },
      tagChange(newTag) {
        newTag = this.cloneComponent(newTag);
        const config = newTag.__config__;
        newTag.__vModel__ = this.activeData.__vModel__;
        config.formId = this.activeId;
        config.span = this.activeData.__config__.span;
        this.activeData.__config__.tag = config.tag;
        this.activeData.__config__.tagIcon = config.tagIcon;
        this.activeData.__config__.document = config.document;
        if (
          typeof this.activeData.__config__.defaultValue ===
          typeof config.defaultValue
        ) {
          config.defaultValue = this.activeData.__config__.defaultValue;
        }
        Object.keys(newTag).forEach(key => {
          if (this.activeData[key] !== undefined) {
            newTag[key] = this.activeData[key];
          }
        });
        this.activeData = newTag;
        this.updateDrawingList(newTag, this.drawingList);
      },
      updateDrawingList(newTag, list) {
        const index = list.findIndex(
          item => item.__config__.formId === this.activeId
        );
        if (index > -1) {
          list.splice(index, 1, newTag);
        } else {
          list.forEach(item => {
            if (Array.isArray(item.__config__.children))
              this.updateDrawingList(newTag, item.__config__.children);
          });
        }
      },
      refreshJson(data) {
        this.drawingList = deepClone(data.fields);
        delete data.fields;
        this.formConf = data;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/styles/home";

  .answer_wrap {
    // background: #fafafa;

    .container {
      margin: 0 auto 120px;
      padding-bottom: 50px;
      box-shadow: 0px 2px 10px 0px #f5f8fa;
      border-radius: 8px;

      .explain {
        padding: 0 47px 20px;
        background: #ffffff;
        border-radius: 8px;
        margin-bottom: 20px;

        .explain_title {
          font-size: 13px;
          font-weight: bold;
          line-height: 49px;
          border-bottom: 1px solid #F5F4F4;
          margin-bottom: 15px;

          &::before {
            content: ' ';
            display: inline-block;
            width: 4px;
            height: 21px;
            background-color: #508EF9;
            margin-right: 11px;
            border-radius: 2px;
            vertical-align: -5px;
          }
        }

        .explain_item {
          padding-left: 15px;
          color: #999999;
          font-size: 12px;
          line-height: 35px;
        }

      }

      .center-board {
        margin: 0;
        padding: 0 47px 20px;
        background: #ffffff;
        border-radius: 8px;

        .workContent {
          border-bottom: 1px solid #F5F4F4;
          font-size: 13px;
          font-weight: bold;
          margin-bottom: 25px;

          span {
            line-height: 49px;
            padding: 0 20px;
            position: relative;
            display: inline-block;
            color: #508EF9;

            &::after {
              position: absolute;
              content: ' ';
              display: inline-block;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #508EF9;
            }
          }
        }

        .center-scrollbar {
          border: 0;
        }

        .center-board-row {
          form {
            .drawing-board {
              .drawing-item-delete {
                display: none;
              }

              .el-collapse {
                border-bottom: none;
                border-top: none;
              }
            }
          }
        }
      }
    }

    @media screen and (min-width:1250px) {
      .container {

        .explain {
          padding: 0 57px 25px;
          margin-bottom: 24px;

          .explain_title {
            font-size: 16px;
            line-height: 60px;

            &::before {
              width: 5px;
              height: 25px;
            }
          }

          .explain_item {
            padding-left: 18px;
            font-size: 13px;
          }

        }

        .center-board {
          padding: 0 57px 40px;

          .workContent {
            font-size: 16px;

            span {
              line-height: 60px;
              padding: 0 25px;
            }
          }

        }

        .submit {
          padding: 0 52px;
          line-height: 38px;
          font-size: 15px;
        }
      }
    }
  }
</style>